








































































import Vue from 'vue';

import { mapState, mapActions } from 'vuex';

import { subscriptionActions } from '@/modules/subscription/store/names';
import { ComposedState } from '@/store/general.types';
import { ChangeParams } from '@/services/admin.service';

export default Vue.extend({
    name: 'EditCurrency',

    props: {
        subscribeId: {
            type: Number,
            required: true,
        },

        // Пропс с ключём из объекта который мы хотим изменить
        changeField: {
            type: String,
            required: true,
        },

        title: {
            type: String,
            required: true,
        },

        name: {
            type: [String, Number],
            required: true,
        },

        // Тип поля с текстом
        type: {
            type: String,
        },
    },

    data() {
        return {
            editNameDialog: false,
            btnState: true,

            setName: '',

            process: {
                state: false,
                preloader: true,
                icon: false,
            },
        };
    },

    computed: {
        ...mapState<ComposedState>({
            changeSubs: (state: ComposedState) => state.subscription.subscribeData,
        }),

        nameModel: {
            get(): string | number {
                return this.name;
            },
            set(val: string) {
                this.setName = val;
            },
        },

        updateNameRules() {
            return [
                (value: string) => {
                    if (value) {
                        this.btnState = false;
                        return true;
                    } 
                    this.btnState = true;
                    return 'Name must be valid';
                    
                },
            ];
        },
    },

    methods: {
        ...mapActions({
            changeSubscribesApi: subscriptionActions.changeSubscribesApi,
        }),

        async submit() {
            this.process.state = true;
            this.process.preloader = true;
            this.process.icon = false;
            await this.changeSubscribesApi({
                id: this.subscribeId,
                change: {

                    // @ts-ignore
                    ...this.changeSubs,
                    [this.changeField]: this.setName,
                } as ChangeParams,
            });
            this.process.preloader = false;
            this.process.icon = true;
            setTimeout(() => {
                this.editNameDialog = false;
                this.process.state = false;
            }, 1000);
        },
    },
});
